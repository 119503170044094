<template>
  <div v-if="listData.length > 0">
    <div class="key-visual">
      <div class="mask">
        <div class="contents">
          <div class="txt">
            We are digital experts<br />
            who will make<br />
            your business greater.
          </div>
          <router-link :to="`/works/${listData[0].id}`" class="key-button">See works</router-link>
          <!--<button type="button" @click="test" class="key-button">See works</button>-->
        </div>
        <div
          class="bg"
          :style="{backgroundImage: `url(${imgUrl(listData[0].pcDetailImagePhysicalName)})`}"
        ></div>
      </div>
    </div>
    <div class="contents">
      <p class="description">
        하나의 프로젝트를 완성하기 위해<br />
        디테일한 부분까지 집중하고 몰입하여<br />
        <span class="fc-red"> 완벽함을 만들어 냅니다.</span>
      </p>
      <ul class="work-list">
        <li
          v-for="(item, index) in listData"
          :key="item.id"
          :ref="`listItem${item.index}`"
          :class="{bigimg: index % 3 === 1}"
          :id="`listItem${index}`"
        >
          <router-link :to="`/works/${item.id}`">
            <div class="thumb">
              <img
                :src="
                  imgUrl(
                    index % 3 === 1 ? item.pcBigImagePhysicalName : item.pcSmallImagePhysicalName
                  )
                "
                @load="handleImageLoad(index, $event.target.clientHeight)"
                alt=""
              />
            </div>
            <div class="info">
              <i class="circle" :style="{background: colorFn(item, index)}" />
              <div
                class="title"
                v-html="handleNewLine(item.projectServiceName)"
                :style="{color: colorFn(item, index)}"
              ></div>
              <div class="desc" :style="{color: colorFn(item, index)}">
                {{ item.projectType }}
              </div>
              <span
                class="more"
                :style="{color: colorFn(item, index), borderBottomColor: colorFn(item, index)}"
                >Read More</span
              >
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import {Power0, TweenMax} from "gsap";
  import {mapGetters} from "vuex";
  import {apiWorksList} from "@/api";
  import {commonMethods} from "@/utils/common-methods";
  export default {
    name: "worksList",
    data() {
      return {
        page: 0,
        size: 15,
        totalPage: 0,
        keyword: "",
        scene: null,
        listData: [],
        loading: false,
        imgHeights: []
      };
    },
    computed: {
      ...mapGetters(["GE_CONT_HEIGHT"])
    },
    watch: {
      /* GE_CONT_HEIGHT() {
        if (this.$el.offsetParent) {
          this.init();
        }
      }*/
    },
    mixins: [commonMethods],
    methods: {
      colorFn(item, index) {
        // console.log('list Item', `this.$ref.listItem${index}`       )
        if (index % 3 === 1) {
          //return item.listFont;
          const bigimg = document.querySelectorAll(".bigimg");
          if (bigimg) {
            return item.detailFont ? item.detailFont : "#fff";
          }
        }
        //return "#f0f";
      },

      infiniteScroll() {
        if (
          this.page < this.totalPage &&
          !this.loading &&
          !(this.GE_CONT_HEIGHT - 1000 >= window.scrollY + window.innerHeight)
        ) {
          this.page = this.page + 1;
          this.fetchData();
        }
      },
      init() {
        //console.log(1);
        this.scene?.destroy(true);
        const keyVisual = document.querySelector(".key-visual")
          ? document.querySelector(".key-visual")
          : null;
        //console.log(keyVisual);
        if (keyVisual) {
          const tween = TweenMax.to(keyVisual.querySelector(".bg"), 1, {
            y: keyVisual.clientHeight,
            ease: Power0.easeInOut
          });
          const tween2 = TweenMax.to(keyVisual.querySelector(".txt"), 1, {
            y: keyVisual.clientHeight + keyVisual.querySelector(".txt").clientHeight,
            ease: Power0.easeInOut
          });
          const tween3 = TweenMax.to(keyVisual.querySelector(".key-button"), 1, {
            y: keyVisual.clientHeight + 200,
            ease: Power0.easeInOut
          });
          this.scene = this.$scrollmagic
            .scene({
              triggerElement: keyVisual,
              triggerHook: 0,
              duration: keyVisual.clientHeight * 2
            })
            .setTween([tween, tween2, tween3]);
          this.$scrollmagic.addScene(this.scene);

          document.querySelectorAll(".work-list li").forEach(el => {
            const tw = TweenMax.fromTo(
              el.querySelector("a"),
              1,
              {
                y: 300
              },
              {
                y: 0,
                ease: Power0.easeInOut
              }
            );
            const sc = this.$scrollmagic
              .scene({
                offset: 0,
                triggerElement: el,
                triggerHook: 1,
                duration: 1000
              })
              .setTween(tw);
            this.$scrollmagic.addScene(sc);
          });
        }
      },
      async fetchData() {
        try {
          const {
            data: {data: response}
          } = await apiWorksList({
            page: this.page,
            size: this.size,
            keyword: this.keyword
          });
          //console.log(response);
          this.totalPage = response.totalPages;
          this.listData = this.listData.concat(response.content);
          this.$nextTick(() => {
            this.loading = false;
            this.init();
          });
        } catch (error) {
          console.error(error);
        }
      },
      async handleImageLoad(index, height) {
        await this.$nextTick();
        this.$set(this.imgHeights, index, height);
        if (height > 700) {
          const liElement = document.getElementById(`listItem${index}`);
          if (liElement) {
            liElement.classList.add("bigimg");
          }
        }
      }
    },
    // mounted() {
    // },
    created() {
      this.fetchData();
    },
    activated() {
      window.addEventListener("scroll", this.infiniteScroll);
      this.init();
    },
    deactivated() {
      window.removeEventListener("scroll", this.infiniteScroll);
    },
    destroyed() {
      this.scene?.destroy(true);
    }
  };
</script>
<style lang="scss" scoped>
  .key-visual {
    position: relative;
    min-height: 1000px;
    height: 100vh;
    min-width: $globalWidth;
    .mask {
      height: 100vh;
      position: relative;
      overflow: hidden;
    }
    .contents {
      z-index: 2;
      display: flex;
      flex-direction: column;
      padding-top: 120px;
      box-sizing: border-box;
      justify-content: center;
      align-items: flex-start;
      height: 100vh;
      min-height: 800px;
      position: relative;
      .txt {
        @include montserrat();
        font-weight: bold;
        font-size: 80px;
        color: $red;
        line-height: 100px;
      }
      .key-button {
        flex: 0 0 auto;
        display: block;
        border: none;
        border-radius: 50%;
        background: $red;
        font-size: 18px;
        @include montserrat();
        color: #fff;
        height: 120px;
        width: 120px;
        text-align: center;
        line-height: 120px;
        margin-top: 120px;
      }
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      min-width: $globalWidth;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
  }
  .description {
    margin-top: 120px;
    font-weight: bold;
    line-height: 74px;
    letter-spacing: -0.05em;
    font-size: 48px;
    color: #a0a0a0;
  }
  .work-list {
    @include clearFix();
    margin-top: 90px;
    padding-bottom: 160px;
    &:before {
      content: "";
      height: 1px;
    }
    li {
      width: 552px;
      //margin-top: 120px;
      margin-top: 200px;
      position: relative;
      &.bigimg {
        .thumb {
          aspect-ratio: 552 / 756;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      a {
        display: block;
        font-family: "Noto Sans KR", Montserrat, sans-serif;
        .thumb {
          border-radius: 20px;
          overflow: hidden;
          transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955),
            -webkit-transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          img {
            vertical-align: top;
            object-fit: cover;
            transform: scaleX(1);
            transition: opacity 0.5s ease 0.1s,
              transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955),
              -webkit-transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          }
        }
        .info {
          transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955),
            -webkit-transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          .circle {
            display: block;
            height: 12px;
            width: 12px;
            margin-top: 30px;
            border-radius: 50%;
            background: $red;
          }
          .title {
            margin-top: 19px;
            font-size: 24px;
            font-weight: bold;
            line-height: 34px;
            color: #000;
          }
          .desc {
            margin-top: 3px;
            font-size: 14px;
            line-height: 28px;
            color: #a0a0a0;
          }
          .more {
            display: inline-block;
            margin-top: 30px;
            font-size: 14px;
            color: #000;
            line-height: 18px;
            border-bottom: 1px solid #000;
          }
        }
        &:hover {
          .thumb {
            transform: scale3d(0.95, 0.95, 1);
            img {
              transform: scale3d(1.15, 1.15, 1);
            }
          }
          /*.info {
          transform: scale3d(0.95, 0.95, 1);
        }*/
        }
      }
    }
    //li:nth-child(3n + 2)
    li.bigimg {
      margin-top: 120px;
      //큰거
      .info {
        position: absolute;
        top: 60px;
        left: 50px;
        bottom: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .circle {
          margin-top: 0;
          background-color: #fff;
        }
        .title {
          font-size: 50px;
          line-height: 64px;
          color: #fff;
        }
        .desc {
          margin-top: auto;
          color: #fff;
        }
        .more {
          color: #fff;
          border-bottom-color: #fff;
        }
      }
    }
    li:first-child {
      margin-top: 0 !important;
    }
    li:nth-child(2) {
      margin-top: 200px;
    }
    li:nth-child(2n + 1) {
      float: left;
      clear: left;
    }
    li:nth-child(2n) {
      float: right;
      clear: right;
    }
    li:nth-child(3n + 1) {
      margin-top: 200px;
    }
  }
</style>
